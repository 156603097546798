import { Box, Heading, Text } from 'grommet'
import React, { FC } from 'react'

const NotFound: FC = () => (
  <>
    <Box justify="center" align="center" fill direction="column">
      <Heading textAlign="center">Oops</Heading>
      <Text size="1.3em" textAlign="center">
        Could not find the page your were looking for.
      </Text>
    </Box>
    <style global jsx>{`
      html {
        background: #000;
      }

      html,
      body,
      body > div:first-child,
      div#__next,
      div#__next > div,
      div#__next > div > div {
        height: 100%;
      }
    `}</style>
  </>
)

export default NotFound
